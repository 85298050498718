/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardTitle,
  Progress,
  ButtonGroup,
  Spinner,
  Badge,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  wei_to_eth,
  wei_per_byte_to_dollars_per_gb,
  display_version,
  display_version_color,
} from "../utils";
import { DeviceEntryReturn, HeartBeatGraphValues, Status } from "../types";
import { FaBroadcastTower, FaKey } from "react-icons/fa";
import { Card, Checkbox, Icon, Tab, Tabs, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// CurrentDevices is defined here so that the RouterCards component can define it and the Monitor Component can use it
// and we only compute it once. This is best left here instead of as a global state var the way we do entire networks'
// device lists because any checkbox handling or button clicking would trigger a rerender otherwise. This was specifically
// put in place because without a way to pass Monitor the list of ONLY devices that were currently displayed to the user,
// i.e. through search/filtering/pagination, the 'select all' button would select all devices in the network.
let currentDevices: DeviceEntryReturn[] = [];
function collection_to_array(input: JSX.Element[]) {
  const outArray = new Array(input.length);
  let i = 0;
  for (const key in input) {
    outArray[i] = input[key];
    i = i + 1;
  }
  return outArray;
}

function format_statuses(
  statuses: Array<[string, string | null]>,
  status_map: Status[],
  trigger_router_statuses_modal: Function,
  name: string
) {
  // Checks to see if we need to append a the second string to the default status message and creates number array
  // for all the status messages we need to display
  const status_flagged: number[] = [];
  const new_status_array: Array<[string, string]> = [];
  let matched_index = 0;

  // This loops creates a deep copy of the status messages and appends messages from their generic message
  // into their custom message such as adding hours elapsed, etc. It also formats line breaks into the string.
  const characters_before_break = 55;
  let cur_characters = 0;
  for (
    let index = 0;
    index < statuses.length && cur_characters < characters_before_break;
    index++
  ) {
    for (
      let status_index = index;
      status_index < status_map.length;
      status_index++
    ) {
      if (statuses[index][0] === status_map[status_index].status) {
        if (statuses[index][1] != null) {
          new_status_array[matched_index] = [
            JSON.parse(JSON.stringify(statuses[index][0])) +
              JSON.parse(JSON.stringify(statuses[index][1])),
            status_map[status_index].color,
          ];
        } else {
          new_status_array[matched_index] = [
            statuses[index][0],
            status_map[status_index].color,
          ];
        }
        cur_characters += new_status_array[matched_index][0].length;
        if (cur_characters >= characters_before_break) {
          break;
        }
        status_flagged[matched_index] = matched_index;

        matched_index++;
        break;
      }
    }
  }
  return (
    <div>
      <li>
        Router statuses:
        {status_flagged.map((value: number) => (
          <Badge
            key={value}
            style={{
              backgroundColor: check_router_status_color(
                new_status_array[value][1]
              ),
              marginLeft: 5,
            }}
          >
            {new_status_array[value][0]}
          </Badge>
        ))}
        <Badge
          style={{
            marginLeft: 5,
          }}
          onClick={() => trigger_router_statuses_modal(statuses, name)}
          disabled={cur_characters < characters_before_break}
          hidden={cur_characters < characters_before_break}
        >
          ...
        </Badge>
      </li>
    </div>
  );
}
function check_router_status_color(color: string) {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  if (reg.test(color)) {
    return color;
  }
  return undefined;
}

// search the entire JSON object from the router, which will include neighbors and other related devices that are not
// the specific router requested.
function router_matches_search(
  device: any,
  query: string,
  display_state: string,
  show_acp: string
) {
  if (display_state !== "search") {
    return device;
  } else if (
    matches_acp_selection(device, show_acp) &&
    JSON.stringify(device).toLowerCase().includes(query.toLowerCase())
  ) {
    return device;
  } else {
    return null;
  }
}

// instead of a blind match on the entire JSON object we now match specifically on these fields of the DeviceEntryReturn
// when searching to reduce the number of random neighbor matches we get
function router_card_matches_search(
  device: DeviceEntryReturn,
  query: string,
  display_state: string,
  show_acp: string
) {
  if (display_state !== "search") {
    return device;
  } else if (
    matches_acp_selection(device, show_acp) &&
    ((device.id.nickname &&
      device.id.nickname.toLowerCase().includes(query.toLowerCase())) ||
      (device.name &&
        device.name.toLowerCase().includes(query.toLowerCase())) ||
      (device.id.eth_address &&
        device.id.eth_address.toLowerCase().includes(query.toLowerCase())) ||
      (device.id.mesh_ip &&
        device.id.mesh_ip.toLowerCase().includes(query.toLowerCase())) ||
      (device.id.wg_public_key &&
        device.id.wg_public_key.toLowerCase().includes(query.toLowerCase())) ||
      (device.contact_details &&
        JSON.stringify(device.contact_details)
          .toLowerCase()
          .includes(query.toLowerCase())) ||
      (device.billing_details &&
        JSON.stringify(device.billing_details)
          .toLowerCase()
          .includes(query.toLowerCase())))
  ) {
    return device;
  } else {
    return null;
  }
}

/// This function checks the string that is input by the user to see if
/// it matches a 3 particular regex expressions of wireguard key, eth
/// address or ipv6. If it matches any of the 3, it then iterates
/// over the device struct to see if the regex matches a device.
function check_regex_expression(
  devices: Array<DeviceEntryReturn>,
  string_to_check: string,
  display_state: string,
  search_related: boolean,
  show_acp: string
) {
  const regex_wg_key = /^[A-Za-z0-9+/]{42}[A|E|I|M|Q|U|Y|c|g|k|o|s|w|4|8|0]=$/;
  const regex_eth_addr = /^0x[a-fA-F0-9]{40}$/;
  const regex_ipv6 =
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;
  if (display_state !== "search") {
    return;
  }
  const ret_devices: Array<DeviceEntryReturn> = [];
  if (string_to_check.match(regex_wg_key)) {
    console.log("matched wg key");
    for (const device of devices) {
      if (
        matches_acp_selection(device, show_acp) &&
        router_check_specific_key(
          device,
          "wg_public_key",
          string_to_check,
          display_state,
          search_related
        )
      ) {
        ret_devices.push(device);
      }
    }
  } else if (string_to_check.match(regex_eth_addr)) {
    for (const device of devices) {
      if (
        matches_acp_selection(device, show_acp) &&
        router_check_specific_key(
          device,
          "eth_address",
          string_to_check,
          display_state,
          search_related
        )
      ) {
        ret_devices.push(device);
      }
    }
  } else if (string_to_check.match(regex_ipv6)) {
    for (const device of devices) {
      if (
        matches_acp_selection(device, show_acp) &&
        router_check_specific_key(
          device,
          "mesh_ip",
          string_to_check,
          display_state,
          search_related
        )
      ) {
        ret_devices.push(device);
      }
    }
  }
  return ret_devices;
}

function matches_acp_selection(device: DeviceEntryReturn, show_acp: string) {
  if (show_acp === "acp" && device.acp) {
    return true;
  } else if (show_acp === "non-acp" && !device.acp) {
    return true;
  } else if (show_acp === "any") {
    return true;
  } else {
    return false;
  }
}

/// Checking a specific device for a json key by iterating through each of the keys
function router_check_specific_key(
  device: any,
  key_query: string,
  value_to_find: string,
  display_state: string,
  search_related: boolean
) {
  if (value_to_find === "") {
    return false;
  }
  if (display_state !== "search") {
    return true;
  } else {
    const string_to_iterate = JSON.stringify(device).toLowerCase();
    const iterate_map = JSON.parse(string_to_iterate);
    ///hard coded since id has a duplicate
    if (iterate_map.id !== undefined && key_query === "id") {
      for (const [, value] of Object.entries(iterate_map.id)) {
        if (
          value &&
          value !== undefined &&
          JSON.stringify(value)
            .toLowerCase()
            .includes(value_to_find.toLowerCase())
        ) {
          console.log("went through id iterate?");
          return true;
        }
      }
    } else if (search_device_entries(key_query, value_to_find, device)) {
      return true;
    } else if (
      search_related &&
      search_nested(iterate_map, key_query, value_to_find)
    ) {
      return true;
    }
    return false;
  }
}
function handle_search(
  devices: Array<DeviceEntryReturn>,
  key_to_search: string,
  value_to_search: string,
  display_state: string,
  search_related: boolean,
  show_acp: string
) {
  const matched_device = [];
  for (const device of devices) {
    if (
      matches_acp_selection(device, show_acp) &&
      router_check_specific_key(
        device,
        key_to_search,
        value_to_search,
        display_state,
        search_related
      )
    ) {
      matched_device.push(device);
    }
  }
  return matched_device;
}
function search_device_entries(
  key_query: string,
  value_to_find: string,
  device: DeviceEntryReturn
): boolean {
  if (key_query == "wg_public_key") {
    if (
      device.id.wg_public_key &&
      device.id.wg_public_key
        .toLowerCase()
        .includes(value_to_find.toLowerCase())
    ) {
      return true;
    }
  } else if (key_query == "eth_address") {
    if (
      device.id.eth_address &&
      device.id.eth_address.toLowerCase().includes(value_to_find.toLowerCase())
    ) {
      return true;
    }
  } else if (key_query == "mesh_ip") {
    if (
      device.id.mesh_ip &&
      device.id.mesh_ip.toLowerCase().includes(value_to_find.toLowerCase())
    ) {
      return true;
    }
  } else if (key_query == "id") {
    if (
      JSON.stringify(device.id)
        .toLowerCase()
        .includes(value_to_find.toLowerCase())
    ) {
      return true;
    }
  } else if (key_query == "name") {
    if (
      device.name &&
      device.name.toLowerCase().includes(value_to_find.toLowerCase())
    ) {
      return true;
    }
  } else if (key_query == "contact") {
    if (
      (device.contact_details &&
        JSON.stringify(device.contact_details)
          .toLowerCase()
          .includes(value_to_find.toLowerCase())) ||
      (device.billing_details &&
        JSON.stringify(device.billing_details)
          .toLowerCase()
          .includes(value_to_find.toLowerCase()))
    ) {
      return true;
    }
  } else if (key_query == "comments") {
    if (
      (device.comments &&
        JSON.stringify(device.comments)
          .toLowerCase()
          .includes(value_to_find.toLowerCase())) ||
      (device.install_details &&
        JSON.stringify(device.install_details)
          .toLowerCase()
          .includes(value_to_find.toLowerCase()))
    ) {
      return true;
    }
  }
  return false;
}

/// Recurisvely searches through a nested json object for the key to iterate through and find the value
function search_nested(
  obj: any,
  key_query: string,
  value_to_find: string
): boolean {
  /// searches for nested keys
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value !== undefined && value !== null) {
      /// We matched on the thing we want to query
      if (key === key_query) {
        if (value && typeof value == "object") {
          /// iterate through matched query array
          for (const [, check] of Object.entries(value)) {
            if (
              JSON.stringify(check)
                .toLowerCase()
                .includes(value_to_find.toLowerCase())
            ) {
              return true;
            }
          }
        } else if (
          value &&
          JSON.stringify(value)
            .toLowerCase()
            .includes(value_to_find.toLowerCase())
        ) {
          return true;
        }
      }
      // iterate through each nested object to search for the key to search over such as id,name, and comments
      else if (value && typeof value === "object") {
        if (search_nested(value, key_query, value_to_find)) {
          return true;
        }
      }
    }
  }
  return false;
}

function get_progress(hour_status: any) {
  const values = hour_status.values;
  //let good = hour_status.good;
  //let warning = hour_status.warning;
  const bad = hour_status.bad;
  const down = hour_status.down;
  // this is the actual array of react elements we will return to render
  const items = [];
  // these are parallel equal length arrays that we generate on the first pass
  // we then perform a second pass to optimize how this is displayed
  // by aggregating blocks of the same condition into single react elements
  // since the Progress tag renders based on an input percentage we need two
  // passes. We can't know that green is 5% of the total bar while still iterating
  // through the data for the first time.
  const conditions = [];
  for (let i = 0; i < values.length; i++) {
    if (values[i] < down) {
      conditions.push("danger");
    } else if (values[i] < bad) {
      conditions.push("warning");
    } else {
      conditions.push("success");
    }
  }

  let last_condition = conditions[0];
  let condition_count = 0;
  // creates the minimum number of progress objects possible by detecting transitions
  // and pushing the progress object for the last unbroken set of a single color
  // remember we push the previous element on the transition to the next one so we
  // have to wrap up and run the last iteration at the end.
  for (let i = 0; i < conditions.length; i++) {
    if (last_condition !== conditions[i]) {
      // the graph is one indexed but the array is zero indexed
      items.push(
        <Progress bar key={i} value={condition_count} color={last_condition} />
      );
      last_condition = conditions[i];
      condition_count = 0;
      // we need to count the new condition we just started
      condition_count++;
    } else {
      condition_count++;
    }
  }
  items.push(
    <Progress bar key={"last"} value={condition_count} color={last_condition} />
  );
  return items;
}

// plots a progress bar using reactstrap that is used to represent
// router checkins. Accepts an array of checkins and a starting number
// assuming we get a checkin every 5 seconds and we want to display a day
function plot_checkins(hour_status: HeartBeatGraphValues) {
  return (
    <div>
      <Progress multi>
        {collection_to_array(get_progress(hour_status))}
      </Progress>
    </div>
  );
}
function no_flagged_status(
  statuses: Array<[string, string | null]>,
  flagged_router_status: boolean[],
  status_map: Status[]
) {
  if (statuses.length === 0) return true;
  for (let index = 0; index < statuses.length; index++) {
    const tuple: [string, string | null] = statuses[index];
    for (
      let status_index = 0;
      status_index < status_map.length;
      status_index++
    ) {
      if (
        tuple[0] === status_map[status_index].status &&
        flagged_router_status[status_index] &&
        status_map[status_index].color !== "#6c757d"
      ) {
        return false;
      }
    }
  }
  return true;
}
/// This function is used by the check boxes for toggling operator actions as multiple devices. This simply
/// returns true or false if the device is in the list which should allow the checkboxes to switch if they're checked.
function check_matched_id(
  device: DeviceEntryReturn,
  checked_list: DeviceEntryReturn[],
  index: number
) {
  if (
    index < checked_list.length &&
    device.id.eth_address === checked_list[index].id.eth_address &&
    device.id.mesh_ip === checked_list[index].id.mesh_ip &&
    device.id.wg_public_key === checked_list[index].id.wg_public_key
  ) {
    return true;
  }
  for (let i = 0; i < checked_list.length; i++) {
    if (
      device.id.eth_address === checked_list[i].id.eth_address &&
      device.id.mesh_ip === checked_list[i].id.mesh_ip &&
      device.id.wg_public_key === checked_list[i].id.wg_public_key
    ) {
      return true;
    }
  }
  return false;
}

interface RouterCardProps {
  device: DeviceEntryReturn;
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_router_statuses_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  status_map: Status[];
  checked_list: DeviceEntryReturn[];
  index: number;
  fiber_mode: boolean;
}

export const RouterCard: React.FC<RouterCardProps> = (props) => {
  // graphs module dropdown
  const [dropDownOpen, setDropDownOpen] = useState<any>(false);
  // router details dropdown modules open state
  const [dropDownOpenRouter, setOpenRouter] = React.useState(false);
  const [displayState, setDisplayState] = useState<number>(0);

  function toggleDropDown() {
    setDropDownOpen(!dropDownOpen);
  }
  function toggleDropDownRouter() {
    setOpenRouter(!dropDownOpenRouter);
  }

  const fifteen_minutes_converted = 15 * 60;
  const one_hour_converted = 1 * 60 * 60;
  const eight_hours_converted = 8 * one_hour_converted;
  const twenty_four_hours_converted = 24 * one_hour_converted;
  const one_week_converted = 7 * 24 * one_hour_converted;
  const one_month_converted = 30 * 24 * one_hour_converted;
  const one_year_converted = 365 * 24 * one_hour_converted;

  let button_text;
  if (displayState === 1) {
    button_text = "15 minutes";
  } else if (displayState === 2) {
    button_text = "1 hour";
  } else if (displayState === 3) {
    button_text = "8 hours";
  } else if (displayState === 4) {
    button_text = "1 day";
  } else if (displayState === 5) {
    button_text = "1 week";
  } else if (displayState === 6) {
    button_text = "1 month";
  } else if (displayState === 7) {
    button_text = "1 year";
  } else {
    button_text = "Graphs";
  }

  const device = props.device;
  if (!device) {
    return <></>;
  }
  let name: string;
  if (device.name) {
    name = device.name;
  } else {
    name = device.id.wg_public_key;
  }
  const balance = wei_to_eth(device.last_balance);
  const price = wei_per_byte_to_dollars_per_gb(device.last_exit_dest_price);
  const latency_to_exit = device.mean_latency_exit;
  const statuses = format_statuses(
    device.r_statuses,
    props.status_map,
    props.trigger_router_statuses_modal,
    name
  );
  let disableDash = false;
  const down = props.device.hour_status.down;
  const len = props.device.hour_status.values.length;
  if (
    props.device.hour_status.values[len - 1] < down &&
    props.device.hour_status.values[len - 2] < down
  ) {
    disableDash = true;
  }
  let isUnbilledKeyLTE: boolean;
  if (device.extra_data?.keylte) {
    isUnbilledKeyLTE = device.extra_data.keylte;
  } else {
    isUnbilledKeyLTE = false;
  }

  // change the button if forwarding is happening
  let forward_button;
  if (device.should_forward) {
    forward_button = (
      <MDButton
        color="warning"
        onClick={() => props.halt_forward(device.id)}
        size="small"
      >
        Stop Dash
      </MDButton>
    );
  } else {
    forward_button = (
      <MDButton
        color="secondary"
        onClick={() => props.trigger_forward_modal(device)}
        size="small"
        disabled={disableDash}
      >
        Antenna Dash
      </MDButton>
    );
  }

  // do not show neighbors button for unbilled keylte routers
  let neighbors_button;
  if (isUnbilledKeyLTE) {
    neighbors_button = <></>;
  } else {
    neighbors_button = (
      <MDButton
        color="secondary"
        onClick={() => props.trigger_topology_modal(device)}
        size="small"
      >
        Neighbors
      </MDButton>
    );
  }

  // do not show graphs button for unbilled keylte routers
  let graphs_button;
  if (isUnbilledKeyLTE) {
    graphs_button = <></>;
  } else {
    graphs_button = (
      <MDBox>
        <ButtonDropdown
          toggle={() => {
            toggleDropDown();
          }}
          isOpen={dropDownOpen}
        >
          <DropdownToggle size="sm" caret>
            <MDButton variant="text" color="white" size="small">
              {button_text}
            </MDButton>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled>Latency/Bandwidth</DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(1);
                props.trigger_network_latencies_modal(
                  device,
                  fifteen_minutes_converted
                );
              }}
            >
              15 minutes
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(2);
                props.trigger_network_latencies_modal(
                  device,
                  one_hour_converted
                );
              }}
            >
              1 hour
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(3);
                props.trigger_network_latencies_modal(
                  device,
                  eight_hours_converted
                );
              }}
            >
              8 hours
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(4);
                props.trigger_network_latencies_modal(
                  device,
                  twenty_four_hours_converted
                );
              }}
            >
              1 day
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(5);
                props.trigger_network_latencies_modal(
                  device,
                  one_week_converted
                );
              }}
            >
              1 week
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(6);
                props.trigger_network_latencies_modal(
                  device,
                  one_month_converted
                );
              }}
            >
              1 month
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDisplayState(7);
                props.trigger_network_latencies_modal(
                  device,
                  one_year_converted
                );
              }}
            >
              1 year
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </MDBox>
    );
  }

  // do not show finances button for unbilled keylte routers
  let finances;
  if (isUnbilledKeyLTE) {
    finances = <></>;
  } else {
    finances = (
      <DropdownItem
        onClick={() => props.trigger_finance_details_modal(device)}
        value="finances"
      >
        Finances
      </DropdownItem>
    );
  }

  let forward_details = <></>;
  if (device.should_forward && device.forward_status) {
    if (String(device.forward_status) === "Forwarding") {
      let url: string;
      const fwd_details = device.should_forward.ForwardMessage;
      if (fwd_details.antenna_port === 443) {
        url =
          "https://" + window.location.hostname + ":" + fwd_details.server_port;
      } else {
        url =
          "http://" + window.location.hostname + ":" + fwd_details.server_port;
      }
      forward_details = (
        <div>
          <div className="text-center">
            Dashboard available at{" "}
            <MDButton onClick={() => window.open(url, "_blank")} color="info">
              {url}
            </MDButton>
          </div>
          <hr />
        </div>
      );
    } else if (String(device.forward_status) === "Errored") {
      forward_details = (
        <div>
          <div className="text-center">Error! Failed to find Antenna</div>
          <hr />
        </div>
      );
    } else if (String(device.forward_status) === "WaitingForRouter") {
      forward_details = (
        <div>
          <div className="text-center">Please wait for forwarding to start</div>
          <hr />
        </div>
      );
    }
  }

  const isLTE = device.extra_data?.type === "TowerInfo";
  const version_string = display_version(device) + (isLTE ? " LTE" : "");
  const version_color = display_version_color(device, version_string);

  const version = <Badge color={version_color}>{version_string}</Badge>;

  let tower_icon;
  if (isLTE) {
    tower_icon = (
      <Badge style={{ float: "right" }}>
        <FaBroadcastTower />
      </Badge>
    );
  }
  let keylte_icon;
  let card_content;
  if (isUnbilledKeyLTE) {
    keylte_icon = (
      <Badge style={{ float: "right" }}>
        <FaKey />
      </Badge>
    );
    card_content = (
      <>
        <div style={{ float: "left" }}>
          <li>KeyLTE Device</li>
          {statuses}
        </div>
      </>
    );
  } else if (props.fiber_mode) {
    card_content = (
      <>
        <div style={{ float: "left" }}>
          <li>Balance: ${balance.toFixed(2)}</li>
          <li>Bandwidth Price: ${price.toFixed(4)}/GB</li>
          {statuses}
        </div>
      </>
    );
  } else {
    card_content = (
      <>
        <div style={{ float: "left" }}>
          <li>Balance: ${balance.toFixed(2)}</li>
          <li>Bandwidth Price: ${price.toFixed(4)}/GB</li>
          <li>Latency to Exit: {latency_to_exit.toFixed(2)}ms</li>
          {statuses}
        </div>
      </>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>
          {name}
          {keylte_icon}
          {tower_icon}{" "}
          {
            <input
              type="checkbox"
              checked={check_matched_id(
                device,
                props.checked_list,
                props.index
              )}
              onChange={() => {
                props.add_to_checked(device);
              }}
            />
          }
        </CardTitle>
        {card_content}
        <div style={{ float: "right" }} className="textRight">
          {version}
        </div>
        <div style={{ clear: "both" }}>
          <div className="text-center">One hour status</div>
          {plot_checkins(device.hour_status)}
        </div>
        <hr></hr>
        {forward_details}
        <div className="text-center">
          <ButtonGroup size="sm">
            <ButtonDropdown
              toggle={() => {
                toggleDropDownRouter();
              }}
              isOpen={dropDownOpenRouter}
            >
              <DropdownToggle size="sm" caret>
                <MDButton variant="text" color="white" size="small">
                  Router
                </MDButton>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => props.trigger_router_details_modal(device)}
                  value="details"
                >
                  Details
                </DropdownItem>
                <DropdownItem
                  onClick={() => props.trigger_comments_modal(device)}
                  value="comments"
                >
                  Comments
                </DropdownItem>
                <DropdownItem
                  onClick={() => props.trigger_contact_details_modal(device)}
                  value="contact"
                >
                  Contact
                </DropdownItem>
                {finances}
              </DropdownMenu>
            </ButtonDropdown>
            {graphs_button}
            {neighbors_button}
            {forward_button}
          </ButtonGroup>
        </div>
      </CardBody>
    </Card>
  );
};

interface RouterCardRow {
  devices: Array<DeviceEntryReturn>;
  // a string representing a potential display state, can be set to Search
  // issues or all, if in a filtered state cards not meeting the filter should
  // not render
  display_state: string;
  search_string: string;
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_router_statuses_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  show_acp: string;
  key_to_search: string;
  status_map: Status[];
  flagged_router_status: boolean[];
  checked_list: DeviceEntryReturn[];
  search_related: boolean;
  fiber_mode: boolean;
}

/// creates one row with a fixed number of columns
const RouterCards: React.FC<RouterCardRow> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };
  const [cardsPerPage] = useState(50);

  const devices = props.devices;

  // We first check regex strings in basic search
  const matched_devices = check_regex_expression(
    devices,
    props.search_string,
    props.display_state,
    props.search_related,
    props.show_acp
  );
  let body;
  // Advanced search is a priority
  if (props.key_to_search !== "any") {
    const searched_devices = handle_search(
      devices,
      props.key_to_search,
      props.search_string,
      props.display_state,
      props.search_related,
      props.show_acp
    );
    body = searched_devices;
  }

  // Basic search but we check regex first
  else if (matched_devices !== undefined && matched_devices.length > 0) {
    body = matched_devices;
  } else if (!props.search_related) {
    // basic search
    body = devices
      .map((device: DeviceEntryReturn) =>
        router_card_matches_search(
          device,
          props.search_string,
          props.display_state,
          props.show_acp
        )
      )
      .filter((device: DeviceEntryReturn) => device !== null);
  } else if (props.search_related) {
    // search with related allowed
    body = devices
      .map((device: DeviceEntryReturn) =>
        router_matches_search(
          device,
          props.search_string,
          props.display_state,
          props.show_acp
        )
      )
      .filter((device: DeviceEntryReturn) => device !== null);
  }
  if (props.display_state == "issues") {
    body = devices
      .map((device: DeviceEntryReturn) =>
        !no_flagged_status(
          device.r_statuses,
          props.flagged_router_status,
          props.status_map
        )
          ? device
          : null
      )
      .filter((device: DeviceEntryReturn) => device !== null);
  }
  const count = body.length;
  const indexOfLastDevice = currentPage * cardsPerPage;
  const indexOfFirstDevice = indexOfLastDevice - cardsPerPage;
  currentDevices = body.slice(indexOfFirstDevice, indexOfLastDevice);

  return currentDevices ? (
    <>
      <MDBox py={2} px={1} width="100%" alignSelf="center">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(count / cardsPerPage)}
          previousLabel=<MDButton color="info" size="small">
            Prev
          </MDButton>
          nextLabel=<MDButton color="info" size="small">
            Next
          </MDButton>
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
          pageLabelBuilder={(page) => (
            <MDButton color="white" size="small">
              {page}
            </MDButton>
          )}
        />
      </MDBox>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {currentDevices.map((device: any, index: number) =>
          fill_router_card(device, props, index)
        )}
      </div>
      <MDBox p={1} width="100%" alignSelf="center">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(count / cardsPerPage)}
          previousLabel=<MDButton color="info" size="small">
            Prev
          </MDButton>
          nextLabel=<MDButton color="info" size="small">
            Next
          </MDButton>
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
          pageLabelBuilder={(page) => (
            <MDButton color="white" size="small">
              {page}
            </MDButton>
          )}
        />
      </MDBox>
    </>
  ) : (
    <div />
  );
};
export interface FillRouterCardProps {
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_router_statuses_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  status_map: Status[];
  checked_list: DeviceEntryReturn[];
  fiber_mode: boolean;
}
export function fill_router_card(
  device: DeviceEntryReturn,
  props: FillRouterCardProps,
  index: number
) {
  return (
    <div key={device.id.wg_public_key}>
      <MDBox p={1}>
        <RouterCard
          device={device}
          trigger_comments_modal={props.trigger_comments_modal}
          trigger_forward_modal={props.trigger_forward_modal}
          trigger_router_details_modal={props.trigger_router_details_modal}
          trigger_contact_details_modal={props.trigger_contact_details_modal}
          trigger_finance_details_modal={props.trigger_finance_details_modal}
          trigger_network_latencies_modal={
            props.trigger_network_latencies_modal
          }
          trigger_topology_modal={props.trigger_topology_modal}
          trigger_router_statuses_modal={props.trigger_router_statuses_modal}
          halt_forward={props.halt_forward}
          delete_device={props.delete_device}
          add_to_checked={props.add_to_checked}
          status_map={props.status_map}
          checked_list={props.checked_list}
          index={index}
          fiber_mode={props.fiber_mode}
        />
      </MDBox>
    </div>
  );
}

export function get_search_key_from_selection(newValue: number) {
  let search_key = "";
  if (newValue === 0) search_key = "any";
  else if (newValue === 1) search_key = "id";
  else if (newValue === 2) search_key = "name";
  else if (newValue === 3) search_key = "comments";
  else if (newValue === 4) search_key = "contact";
  return search_key;
}

interface MonitorProps {
  network_data: any;
  checked_list: DeviceEntryReturn[];
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_multiple_routers_modal: Function;
  trigger_router_statuses_modal: Function;
  trigger_filter_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  trigger_monitor_sort: Function;
  sort_devices_string: string;
  get_statuses: Function;
  status_map: Status[];
  flagged_router_status: boolean[];
  toggle_filter_modal: Function;
  set_on_off_selection: Function;
}

// todo look at the json return type for network data and make a type for it
const Monitor: React.FC<MonitorProps> = (props) => {
  const networkdata = props.network_data;
  const [relatedDevicesSearch, setRelatedDevicesSearch] = useState<any>(false);
  const [acpSelection, setAcpSelection] = useState<number>(0);
  const [searchDropDownOpen, setSearchDropDownOpen] = useState<any>(false);
  const [searchString, setSearchString] = useState<any>("");
  const [displayState, setDisplayState] = useState<any>("");
  const [sortStringDropDownOpen, setSortStringDropDownOpen] =
    useState<any>(false);
  const [showAcp, setshowAcp] = useState<string>("any");
  const [keySelection, setKeySelection] = useState<number>(0);
  const [allRoutersSelected, setAllRoutersSelected] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  let fiber_mode: boolean;
  if (!networkdata || !networkdata.fiber_mode) {
    fiber_mode = false;
  } else {
    fiber_mode = networkdata.fiber_mode;
  }
  function toggleSearchDropDown() {
    setSearchDropDownOpen(!searchDropDownOpen);
  }

  function toggleSetSortStringDropDown() {
    setSortStringDropDownOpen(!sortStringDropDownOpen);
  }

  function toggleRelatedDevices() {
    setRelatedDevicesSearch(!relatedDevicesSearch);
  }

  const handleSetAcpSelection = (event: any, newValue: number) => {
    if (newValue === 0) setshowAcp("any");
    else if (newValue === 1) setshowAcp("acp");
    else if (newValue === 2) setshowAcp("non-acp");
    setAcpSelection(newValue);
  };

  const handleSetKeySelection = (event: any, newValue: number) => {
    setKeySelection(newValue);
  };

  function handleClearSearch(event: any) {
    setSearchString("");
    setKeySelection(0);
    setAcpSelection(0);
    setRelatedDevicesSearch(false);
  }

  async function getStatuses() {
    console.log("Retrying router statuses!");
    await props.get_statuses();
  }

  if (
    !props.network_data ||
    !props.status_map ||
    props.status_map.length == 0
  ) {
    if (!initialized) {
      getStatuses();
      setInitialized(true);
      console.log("Getting users list");
    }
    console.log("Already called get statuses, waiting...");
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  if (
    props.network_data === null ||
    props.network_data.devices === null ||
    props.network_data.devices.length === 0
  ) {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        Unable to view any router/network
      </div>
    );
  }

  // if we have less than 25 users display them all by default, once you exceed
  // that number display only those having issues by default
  if (props.network_data.devices.length > 25 && displayState === "") {
    setDisplayState("issues");
  } else if (displayState === "") {
    setDisplayState("all");
  }

  let search_text;
  if (displayState === "issues") {
    search_text = "Users experiencing issues";
  } else if (displayState === "search") {
    search_text = "Search Users";
  } else if (displayState === "all") {
    search_text = "All Users";
  }

  let related_checkbox = <></>;
  let acp_shown = <></>;
  let search_keys = <></>;
  let clear_search = <></>;
  if (displayState === "search") {
    related_checkbox = (
      <MDBox ml={1} alignItems="flex-start" display="flex">
        <Checkbox
          defaultChecked={false}
          onClick={() => toggleRelatedDevices()}
        />
        <MDBox mt={1} alignItems="flex-start" display="flex">
          <MDBox mr={1}>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              Show Related Devices
            </MDTypography>
          </MDBox>
          <Tooltip
            title="Related devices searches all device fields and may include neighbors of search matches"
            placement="bottom"
          >
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              iconOnly
              circular
            >
              <Icon sx={{ cursor: "pointer" }}>question_mark</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      </MDBox>
    );
    acp_shown = (
      <MDBox ml={2} alignItems="flex-start" display="flex">
        <MDBox mt={1} mr={1} alignItems="flex-start" display="flex">
          <MDTypography variant="body2" fontWeight="regular" color="text">
            ACP Status
          </MDTypography>
        </MDBox>
        <Tabs
          orientation="horizontal"
          value={acpSelection}
          onChange={handleSetAcpSelection}
        >
          <Tab label="Any" />
          <Tab label="ACP" />
          <Tab label="Non-ACP" />
        </Tabs>
      </MDBox>
    );
    search_keys = (
      <MDBox ml={2} alignItems="flex-start" display="flex">
        <MDBox mt={1} mr={1} alignItems="flex-start" display="flex">
          <MDTypography variant="body2" fontWeight="regular" color="text">
            Keys
          </MDTypography>
        </MDBox>
        <Tabs
          orientation="horizontal"
          value={keySelection}
          onChange={handleSetKeySelection}
        >
          <Tab label="Any" />
          <Tab label="ID" />
          <Tab label="Name" />
          <Tab label="Comments" />
          <Tab label="Contact" />
        </Tabs>
      </MDBox>
    );
    clear_search = (
      <MDBox ml={2} alignItems="flex-start" display="flex">
        <MDButton
          variant="outlined"
          color="secondary"
          mt={1}
          mr={1}
          onClick={(e) => handleClearSearch(e)}
        >
          Clear Search
        </MDButton>
      </MDBox>
    );
  }

  return (
    <div key="monitor-page">
      <InputGroup>
        <MDBox>
          <ButtonDropdown
            toggle={() => {
              toggleSetSortStringDropDown();
            }}
            isOpen={sortStringDropDownOpen}
          >
            <DropdownToggle size="sm" caret>
              <MDButton variant="text" color="white" size="small">
                {props.sort_devices_string}
              </MDButton>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Default");
                }}
              >
                Default
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Balance");
                }}
              >
                Balance
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Date");
                }}
              >
                Date
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Latency");
                }}
              >
                Latency
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Down");
                }}
              >
                Down Status
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </MDBox>
        <MDBox>
          <ButtonDropdown
            toggle={() => {
              toggleSearchDropDown();
            }}
            isOpen={searchDropDownOpen}
          >
            <DropdownToggle size="sm" caret>
              <MDButton variant="text" color="white" size="small">
                {search_text}
              </MDButton>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => setDisplayState("issues")}
                value="issues"
              >
                Users experiencing issues
              </DropdownItem>
              <DropdownItem value="all" onClick={() => setDisplayState("all")}>
                All Users
              </DropdownItem>
              <DropdownItem
                value="search"
                onClick={() => setDisplayState("search")}
              >
                Search Users
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </MDBox>
        <MDInput
          disabled={displayState !== "search"}
          autoFocus={true}
          type="text"
          // logic to not display last search query while in other modes
          value={displayState === "search" ? searchString : ""}
          placeholder={displayState === "search" ? searchString : ""}
          onChange={(e: { target: { value: any } }) => {
            setSearchString(e.target.value);
          }}
        />
        {related_checkbox}
        {acp_shown}
        {search_keys}
        {clear_search}
      </InputGroup>
      <InputGroup>
        <MDButton
          disabled={props.checked_list.length === 0}
          onClick={() => {
            props.trigger_multiple_routers_modal(props.checked_list);
          }}
          color="secondary"
        >
          View Actions
        </MDButton>
        <MDButton
          onClick={() => {
            props.set_on_off_selection(allRoutersSelected, currentDevices);
            setAllRoutersSelected(!allRoutersSelected);
          }}
          color="secondary"
        >
          {allRoutersSelected
            ? "Clear all selected routers"
            : "Select all routers"}
        </MDButton>
        <MDButton
          hidden={search_text === "Users experiencing issues" ? false : true}
          onClick={() => props.trigger_filter_modal()}
          color="secondary"
        >
          Filter Router Status Tags
        </MDButton>
      </InputGroup>
      {networkdata && (
        <RouterCards
          devices={networkdata.devices}
          fiber_mode={fiber_mode}
          search_string={searchString}
          display_state={displayState}
          trigger_comments_modal={props.trigger_comments_modal}
          trigger_forward_modal={props.trigger_forward_modal}
          trigger_router_details_modal={props.trigger_router_details_modal}
          trigger_contact_details_modal={props.trigger_contact_details_modal}
          trigger_topology_modal={props.trigger_topology_modal}
          trigger_finance_details_modal={props.trigger_finance_details_modal}
          trigger_network_latencies_modal={
            props.trigger_network_latencies_modal
          }
          trigger_router_statuses_modal={props.trigger_router_statuses_modal}
          halt_forward={props.halt_forward}
          delete_device={props.delete_device}
          add_to_checked={props.add_to_checked}
          show_acp={showAcp}
          status_map={props.status_map}
          flagged_router_status={props.flagged_router_status}
          checked_list={props.checked_list}
          search_related={relatedDevicesSearch}
          key_to_search={get_search_key_from_selection(keySelection)}
        ></RouterCards>
      )}
    </div>
  );
};

export default Monitor;
