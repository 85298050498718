import { Tabs, Tab, Tooltip, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Spinner,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { DeviceEntryReturn, NetworkEntry, Status } from "types";
import { fill_router_card, get_search_key_from_selection } from "./Monitor";

// CurrentDevices is defined here so that the RouterCards component can define it and the Monitor Component can use it
// and we only compute it once. This is best left here instead of as a global state var the way we do entire networks'
// device lists because any checkbox handling or button clicking would trigger a rerender otherwise. This was specifically
// put in place because without a way to pass Monitor the list of ONLY devices that were currently displayed to the user,
// i.e. through search/filtering/pagination, the 'select all' button would select all devices in the network.
let currentDevices: DeviceEntryReturn[] = [];

interface RouterCardRow {
  devices: Array<DeviceEntryReturn>;
  search_string: string;
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_router_statuses_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  key_to_search: string;
  status_map: Status[];
  flagged_router_status: boolean[];
  checked_list: DeviceEntryReturn[];
  fiber_mode: boolean;
}

/// creates one row with a fixed number of columns
const RouterCards: React.FC<RouterCardRow> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };
  const [cardsPerPage] = useState(50);

  const devices = props.devices;

  const count = devices.length;
  const indexOfLastDevice = currentPage * cardsPerPage;
  const indexOfFirstDevice = indexOfLastDevice - cardsPerPage;
  currentDevices = devices.slice(indexOfFirstDevice, indexOfLastDevice);

  return currentDevices ? (
    <>
      <MDBox py={2} px={1} width="100%" alignSelf="center">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(count / cardsPerPage)}
          previousLabel=<MDButton color="info" size="small">
            Prev
          </MDButton>
          nextLabel=<MDButton color="info" size="small">
            Next
          </MDButton>
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
          pageLabelBuilder={(page) => (
            <MDButton color="white" size="small">
              {page}
            </MDButton>
          )}
        />
      </MDBox>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {currentDevices.map((device: any, index: number) =>
          fill_router_card(device, props, index)
        )}
      </div>
      <MDBox p={1} width="100%" alignSelf="center">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(count / cardsPerPage)}
          previousLabel=<MDButton color="info" size="small">
            Prev
          </MDButton>
          nextLabel=<MDButton color="info" size="small">
            Next
          </MDButton>
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
          pageLabelBuilder={(page) => (
            <MDButton color="white" size="small">
              {page}
            </MDButton>
          )}
        />
      </MDBox>
    </>
  ) : (
    <div />
  );
};

interface SearchMonitorProps {
  network_data: NetworkEntry | null;
  checked_list: DeviceEntryReturn[];
  trigger_comments_modal: Function;
  trigger_forward_modal: Function;
  trigger_router_details_modal: Function;
  trigger_contact_details_modal: Function;
  trigger_finance_details_modal: Function;
  trigger_network_latencies_modal: Function;
  trigger_topology_modal: Function;
  trigger_multiple_routers_modal: Function;
  trigger_router_statuses_modal: Function;
  halt_forward: Function;
  delete_device: Function;
  add_to_checked: Function;
  trigger_monitor_sort: Function;
  sort_devices_string: string;
  get_statuses: Function;
  status_map: Status[];
  flagged_router_status: boolean[];
  set_on_off_selection: Function;
  search_all_devices: Function;
}

export const SearchMonitor: React.FC<SearchMonitorProps> = (props) => {
  const [searchString, setSearchString] = useState<any>("");
  const [sortStringDropDownOpen, setSortStringDropDownOpen] =
    useState<any>(false);
  const [keySelection, setKeySelection] = useState<number>(0);
  const [allRoutersSelected, setAllRoutersSelected] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  let devices: DeviceEntryReturn[] = [];
  if (props.network_data && props.network_data.devices) {
    devices = props.network_data.devices;
  } else {
    devices = [];
  }

  function toggleSetSortStringDropDown() {
    setSortStringDropDownOpen(!sortStringDropDownOpen);
  }

  const handleSetKeySelection = (event: any, newValue: number) => {
    setKeySelection(newValue);
  };

  function handleClearSearch() {
    setSearchString("");
    setKeySelection(0);
  }

  async function handleSearch() {
    await props.search_all_devices(
      searchString,
      get_search_key_from_selection(keySelection)
    );
    document.getElementById("searchbutton").removeAttribute("disabled");

  }

  async function getStatuses() {
    console.log("Retrying router statuses!");
    await props.get_statuses();
  }

  if (!props.status_map || props.status_map.length == 0) {
    if (!initialized) {
      getStatuses();
      setInitialized(true);
      console.log("Getting users list");
    }
    console.log("Already called get statuses, waiting...");
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  const tooltip = (
    <MDBox mt={1} alignItems="flex-start" display="flex">
      <MDBox mr={2}></MDBox>
      <Tooltip
        title="Search for devices in all of your networks. Selecting a key will limit search to that field of the devices. Searches must be at least 5 characters long."
        placement="bottom"
      >
        <MDButton
          variant="outlined"
          color="secondary"
          size="small"
          iconOnly
          circular
        >
          <Icon sx={{ cursor: "pointer" }}>question_mark</Icon>
        </MDButton>
      </Tooltip>
    </MDBox>
  );

  const search_keys = (
    <MDBox ml={2} alignItems="flex-start" display="flex">
      <MDBox mt={1} mr={1} alignItems="flex-start" display="flex">
        <MDTypography variant="body2" fontWeight="regular" color="text">
          Keys
        </MDTypography>
      </MDBox>
      <Tabs
        orientation="horizontal"
        value={keySelection}
        onChange={handleSetKeySelection}
      >
        <Tab label="Any" />
        <Tab label="ID" />
        <Tab label="Name" />
        <Tab label="Comments" />
        <Tab label="Contact" />
      </Tabs>
    </MDBox>
  );
  const clear_search = (
    <MDBox ml={2} alignItems="flex-start" display="flex">
      <MDButton
        variant="outlined"
        color="secondary"
        mt={1}
        mr={1}
        onClick={() => handleClearSearch()}
      >
        Clear Search
      </MDButton>
    </MDBox>
  );
  const search_button = (
    <MDBox ml={2} alignItems="flex-start" display="flex">
      <MDButton
        id="searchbutton"
        disabled={
          searchString.length < 5
        }
        color="info"
        mt={1}
        mr={1}
        onClick={() => {
            document.getElementById("searchbutton").setAttribute("disabled", "true");
            handleSearch();
        }}
      >
        Search
      </MDButton>
    </MDBox>
  );

  return (
    <div key="monitor-search-page">
      <InputGroup>
        <MDBox>
          <ButtonDropdown
            toggle={() => {
              toggleSetSortStringDropDown();
            }}
            isOpen={sortStringDropDownOpen}
          >
            <DropdownToggle size="sm" caret>
              <MDButton variant="text" color="white" size="small">
                {props.sort_devices_string}
              </MDButton>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Default");
                }}
              >
                Default
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Balance");
                }}
              >
                Balance
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Date");
                }}
              >
                Date
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Latency");
                }}
              >
                Latency
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.trigger_monitor_sort("Down");
                }}
              >
                Down Status
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </MDBox>
        <MDInput
          autoFocus={true}
          type="text"
          // logic to not display last search query while in other modes
          value={searchString}
          placeholder={"Search all Devices (5 character min)"}
          onChange={(e: { target: { value: any } }) => {
            setSearchString(e.target.value);
          }}
        />
        {tooltip}
        {search_keys}
        {search_button}
        {clear_search}
      </InputGroup>
      <InputGroup>
        <MDButton
          disabled={props.checked_list.length == 0}
          onClick={() => {
            props.trigger_multiple_routers_modal(props.checked_list);
          }}
          color="secondary"
        >
          View Actions
        </MDButton>
        <MDButton
          onClick={() => {
            props.set_on_off_selection(allRoutersSelected, currentDevices);
            setAllRoutersSelected(!allRoutersSelected);
          }}
          color="secondary"
        >
          {allRoutersSelected
            ? "Clear all selected routers"
            : "Select all routers"}
        </MDButton>
      </InputGroup>
      {props.network_data && props.network_data.devices && (
        <RouterCards
          devices={devices}
          fiber_mode={false}
          search_string={searchString}
          trigger_comments_modal={props.trigger_comments_modal}
          trigger_forward_modal={props.trigger_forward_modal}
          trigger_router_details_modal={props.trigger_router_details_modal}
          trigger_contact_details_modal={props.trigger_contact_details_modal}
          trigger_topology_modal={props.trigger_topology_modal}
          trigger_finance_details_modal={props.trigger_finance_details_modal}
          trigger_network_latencies_modal={
            props.trigger_network_latencies_modal
          }
          trigger_router_statuses_modal={props.trigger_router_statuses_modal}
          halt_forward={props.halt_forward}
          delete_device={props.delete_device}
          add_to_checked={props.add_to_checked}
          status_map={props.status_map}
          flagged_router_status={props.flagged_router_status}
          checked_list={props.checked_list}
          key_to_search={get_search_key_from_selection(keySelection)}
        ></RouterCards>
      )}
    </div>
  );
};

export default SearchMonitor;
